export const StringsHi = {
  messages: {
    required: (field, ...arg) =>
      `फील्ड ${StringsHi.fields[field] || field} की आवश्यकता है।`,
    min: (field, ...arg) =>
      `फील्ड ${StringsHi.fields[field] || field} कम से कम ${
        arg[0] || 0
      } अक्षर लंबा होना चाहिए।`,
    max: (field, ...arg) =>
      `फील्ड ${StringsHi.fields[field] || field} अधिकतम ${
        arg[0] || 0
      } अक्षर लंबा होना चाहिए।`,
    email: (field, ...arg) =>
      `फील्ड ${StringsHi.fields[field] || field} एक मान्य ईमेल पता होना चाहिए।`,
    mobile: (field, ...arg) =>
      `फील्ड ${
        StringsHi.fields[field] || field
      } एक मान्य 10-अंकीय संख्या होनी चाहिए।`,
    confirm: (field, ...arg) =>
      `फील्ड ${StringsHi.fields[field] || field} को ${
        StringsHi.fields[arg[0]] || arg[0]
      } के समान होना चाहिए।`,
    uniqueuser: (field, ...arg) =>
      `${StringsHi.fields[field] || field} पहले ही लिया जा चुका है`,
  },
  fields: {
    name: "नाम",
    first_name: "पहला नाम",
    last_name: "अंतिम नाम",
    email: "ईमेल",
    mobile: "मोबाइल",
    currentPassword: "वर्तमान पासवर्ड",
    password: "पासवर्ड",
    cpassword: "पुनः दर्ज करें पासवर्ड",
    accept_legal_agreement: "कानूनी समझौता",

    __lawyer_PrimaryImageId: "व्यक्ति की छवि",
    __lawyer_SecondaryImageIds: "चित्र",
    __lawyer_Title: "नाम",
    __lawyer_Category: "श्रेणी",
    __lawyer_SubCategory1: "उप श्रेणी1",
    __lawyer_SubCategory2: "उप श्रेणी2",
    __lawyer_Identifier: "लाइसेंस",
    __lawyer_DisplayDate: "अभ्यास तब से",
    __lawyer_SubTitle: "योग्यता",
    __lawyer_email_address: "ईमेल पता",
    __lawyer_phone_number: "फोन नंबर",
    __lawyer_address: "पता",
    __lawyer_website_link: "वेबसाइट लिंक",
    __lawyer_Property1: "राज्य",
    __lawyer_Property2: "शहर",
    __lawyer_Property3: "ज़िला",
    __lawyer_PropertyList1: "विशेषज्ञता का क्षेत्र",
    __lawyer_PropertyList2: "अदालतें",
    __lawyer_PropertyList3: "बोली जाने वाली भाषाएं",
    __lawyer_Detail1: "प्रोफाइल विवरण",
    __lawyer_Price: "प्रति घंटा मूल्य",

    __firm_PrimaryImageId: "कंपनी की छवि",
    __firm_SecondaryImageIds: "चित्र",
    __firm_Title: "नाम",
    __firm_Category: "श्रेणी",
    __firm_SubCategory1: "उप श्रेणी1",
    __firm_SubCategory2: "उप श्रेणी2",
    __firm_Identifier: "लाइसेंस",
    __firm_DisplayDate: "स्थापना वर्ष",
    __firm_SubTitle: "उप शीर्षक या टैगलाइन",
    __firm_email_address: "ईमेल पता",
    __firm_phone_number: "फोन नंबर",
    __firm_address: "पता",
    __firm_website_link: "वेबसाइट लिंक",
    __firm_Property1: "राज्य",
    __firm_Property2: "शहर",
    __firm_Property3: "ज़िला",
    __firm_PropertyList1: "विशेषज्ञता का क्षेत्र",
    __firm_PropertyList2: "अदालतें",
    __firm_PropertyList3: "बोली जाने वाली भाषाएं",
    __firm_Detail1: "प्रोफाइल विवरण",
    __firm_Price: "प्रति घंटा मूल्य",

    __book_PrimaryImageId: "पुस्तक का सामने का हिस्सा",
    __book_SecondaryImageIds: "चित्र",
    __book_Title: "पुस्तक का शीर्षक",
    __book_Category: "श्रेणी",
    __book_SubCategory1: "उप श्रेणी1",
    __book_SubCategory2: "उप श्रेणी2",
    __book_Identifier: "लाइसेंस",
    __book_DisplayDate: "प्रकाशन तिथि",
    __book_SubTitle: "लेखक",
    __book_email_address: "ईमेल पता",
    __book_phone_number: "फोन नंबर",
    __book_address: "पता",
    __book_website_link: "वेबसाइट लिंक",
    __book_Property1: "राज्य",
    __book_Property2: "शहर",
    __book_Property3: "ज़िला",
    __book_PropertyList1: "टैग",
    __book_PropertyList2: "अदालतें",
    __book_PropertyList3: "बोली जाने वाली भाषाएं",
    __book_Detail1: "पुस्तक विवरण",
    __book_Price: "खरीद मूल्य",
  },
  profile: {
    first_name: "पहला नाम",
    last_name: "अंतिम नाम",
    email: "ईमेल आईडी",
    mobile: "मोबाइल नंबर",
    currentPassword: "वर्तमान पासवर्ड",
    password: "पासवर्ड",
    cpassword: "पासवर्ड फिर से दर्ज करें",
    accept_legal_agreement: "कानूनी सहमति",

    __lawyer_PrimaryImageId: "व्यक्ति की छवि",
    __lawyer_SecondaryImageIds: "पुरस्कार, समाचार आदि की छवियां",
    __lawyer_Title: "वकील का नाम",
    __lawyer_Category: "श्रेणी",
    __lawyer_SubCategory1: "उप श्रेणी 1",
    __lawyer_SubCategory2: "उप श्रेणी 2",
    __lawyer_Identifier: "लाइसेंस",
    __lawyer_DisplayDate: "प्रैक्टिस की शुरुआत से",
    __lawyer_SubTitle: "योग्यता",
    __lawyer_email_address: "ईमेल पता",
    __lawyer_phone_number: "फोन नंबर",
    __lawyer_address: "पता",
    __lawyer_website_link: "वेबसाइट लिंक",
    __lawyer_Property1: "राज्य",
    __lawyer_Property2: "शहर",
    __lawyer_Property3: "ज़िला",
    __lawyer_PropertyList1: "विशेषज्ञता क्षेत्र",
    __lawyer_PropertyList2: "अदालतें",
    __lawyer_PropertyList3: "बोली जाने वाली भाषाएं",
    __lawyer_Detail1: "प्रोफ़ाइल विवरण",
    __lawyer_Price: "प्रति घंटा शुल्क",

    __firm_PrimaryImageId: "फर्म की छवि",
    __firm_SecondaryImageIds: "पुरस्कार, समाचार आदि की छवियां",
    __firm_Title: "फर्म का नाम",
    __firm_Category: "श्रेणी",
    __firm_SubCategory1: "उप श्रेणी 1",
    __firm_SubCategory2: "उप श्रेणी 2",
    __firm_Identifier: "लाइसेंस",
    __firm_DisplayDate: "स्थापना वर्ष",
    __firm_SubTitle: "उपशीर्षक या टैगलाइन",
    __firm_email_address: "ईमेल पता",
    __firm_phone_number: "फोन नंबर",
    __firm_address: "पता",
    __firm_website_link: "वेबसाइट लिंक",
    __firm_Property1: "राज्य",
    __firm_Property2: "शहर",
    __firm_Property3: "ज़िला",
    __firm_PropertyList1: "विशेषज्ञता क्षेत्र",
    __firm_PropertyList2: "अदालतें",
    __firm_PropertyList3: "बोली जाने वाली भाषाएं",
    __firm_Detail1: "प्रोफ़ाइल विवरण",
    __firm_Price: "प्रति घंटा शुल्क",

    __book_PrimaryImageId: "पुस्तक का सामने का भाग",
    __book_SecondaryImageIds: "पुस्तक का पिछला भाग, भूमिका आदि",
    __book_Title: "पुस्तक का शीर्षक",
    __book_Category: "श्रेणी",
    __book_SubCategory1: "उप श्रेणी 1",
    __book_SubCategory2: "उप श्रेणी 2",
    __book_Identifier: "लाइसेंस",
    __book_DisplayDate: "प्रकाशन तिथि",
    __book_SubTitle: "लेखक",
    __book_email_address: "ईमेल पता",
    __book_phone_number: "फोन नंबर",
    __book_address: "पता",
    __book_website_link: "वेबसाइट लिंक",
    __book_Property1: "राज्य",
    __book_Property2: "शहर",
    __book_Property3: "ज़िला",
    __book_PropertyList1: "टैग",
    __book_PropertyList2: "अदालतें",
    __book_PropertyList3: "बोली जाने वाली भाषाएं",
    __book_Detail1: "पुस्तक विवरण",
    __book_Price: "खरीद मूल्य",
  },
  menu: {
    home: "होम",
    about: "हमारे बारे में",
    products: "उत्पाद",
    contact: "संपर्क करें",
    account: "मेरा खाता",
    login: "लॉग इन",
    logout: "लॉग आउट",
    signup: "साइन अप",
  },
  subscription: {
    title: "न्यूज़लेटर",
    description: "अंतिम अपडेट और समाचार प्राप्त करने के लिए सब्सक्राइब करें",
    button: "अब सब्सक्राइब करें",
    success:
      "सदस्यता के लिए धन्यवाद। हम संपर्क में रहने और आपको शानदार सामग्री लाने के लिए उत्सुक हैं!",
    footer: "हम आपकी गोपनीयता का सम्मान करते हैं।",
    name: "पहला नाम",
    email: "आपका ईमेल",
  },
  news: {
    popular_category: "लोकप्रिय श्रेणी",
    recent_more: "हाल की और खबरें",
  },
};
