export const StringsAr = {
  messages: {
    required: (field, ...arg) =>
      `حقل ${StringsAr.fields[field] || field} مطلوب.`,
    min: (field, ...arg) =>
      `حقل ${StringsAr.fields[field] || field} يجب أن يكون طوله على الأقل ${
        arg[0] || 0
      } حرفًا.`,
    max: (field, ...arg) =>
      `حقل ${StringsAr.fields[field] || field} يجب أن يكون طوله أقصى ${
        arg[0] || 0
      } حرفًا.`,
    email: (field, ...arg) =>
      `حقل ${
        StringsAr.fields[field] || field
      } يجب أن يكون عنوان بريد إلكتروني صحيح.`,
    mobile: (field, ...arg) =>
      `حقل ${
        StringsAr.fields[field] || field
      } يجب أن يكون رقمًا صالحًا مكونًا من 10 أرقام.`,
    confirm: (field, ...arg) =>
      `حقل ${StringsAr.fields[field] || field} يجب أن يكون مشابهًا لـ ${
        StringsAr.fields[arg[0]] || arg[0]
      }.`,
    uniqueuser: (field, ...arg) =>
      `لقد تم أخذ ${StringsAr.fields[field] || field} بالفعل.`,
  },
  fields: {
    name: "اسم",
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    email: "البريد الإلكتروني",
    mobile: "رقم الهاتف",
    currentPassword: "كلمة المرور الحالية",
    password: "كلمة المرور",
    cpassword: "أعد إدخال كلمة المرور",
    accept_legal_agreement: "الاتفاقية القانونية",

    __lawyer_PrimaryImageId: "صورة الشخص",
    __lawyer_SecondaryImageIds: "صور الجوائز، الأخبار وغيرها",
    __lawyer_Title: "اسم المحامي",
    __lawyer_Category: "الفئة",
    __lawyer_SubCategory1: "الفئة الفرعية 1",
    __lawyer_SubCategory2: "الفئة الفرعية 2",
    __lawyer_Identifier: "الترخيص",
    __lawyer_DisplayDate: "ممارسة منذ",
    __lawyer_SubTitle: "المؤهل",
    __lawyer_email_address: "عنوان البريد الإلكتروني",
    __lawyer_phone_number: "رقم الهاتف",
    __lawyer_address: "العنوان",
    __lawyer_website_link: "رابط الموقع الإلكتروني",
    __lawyer_Property1: "الولاية",
    __lawyer_Property2: "المدينة",
    __lawyer_Property3: "يصرف",
    __lawyer_PropertyList1: "مجال التخصص",
    __lawyer_PropertyList2: "المحاكم",
    __lawyer_PropertyList3: "اللغات المحكية",
    __lawyer_Detail1: "تفاصيل الملف الشخصي",
    __lawyer_Price: "السعر بالساعة",

    __firm_PrimaryImageId: "صورة الشركة",
    __firm_SecondaryImageIds: "صور الجوائز، الأخبار وغيرها",
    __firm_Title: "اسم الشركة",
    __firm_Category: "الفئة",
    __firm_SubCategory1: "الفئة الفرعية 1",
    __firm_SubCategory2: "الفئة الفرعية 2",
    __firm_Identifier: "الترخيص",
    __firm_DisplayDate: "تأسست في",
    __firm_SubTitle: "عنوان فرعي أو شعار",
    __firm_email_address: "عنوان البريد الإلكتروني",
    __firm_phone_number: "رقم الهاتف",
    __firm_address: "العنوان",
    __firm_website_link: "رابط الموقع الإلكتروني",
    __firm_Property1: "الولاية",
    __firm_Property2: "المدينة",
    __firm_Property3: "يصرف",
    __firm_PropertyList1: "مجال التخصص",
    __firm_PropertyList2: "المحاكم",
    __firm_PropertyList3: "اللغات المحكية",
    __firm_Detail1: "تفاصيل الملف الشخصي",
    __firm_Price: "السعر بالساعة",

    __book_PrimaryImageId: "غلاف الكتاب الأمامي",
    __book_SecondaryImageIds: "الغلاف الخلفي للكتاب، المقدمة، إلخ",
    __book_Title: "عنوان الكتاب",
    __book_Category: "الفئة",
    __book_SubCategory1: "الفئة الفرعية 1",
    __book_SubCategory2: "الفئة الفرعية 2",
    __book_Identifier: "الترخيص",
    __book_DisplayDate: "تاريخ النشر",
    __book_SubTitle: "المؤلف",
    __book_email_address: "عنوان البريد الإلكتروني",
    __book_phone_number: "رقم الهاتف",
    __book_address: "العنوان",
    __book_website_link: "رابط الموقع الإلكتروني",
    __book_Property1: "الولاية",
    __book_Property2: "المدينة",
    __book_Property3: "يصرف",
    __book_PropertyList1: "الوسوم",
    __book_PropertyList2: "المحاكم",
    __book_PropertyList3: "اللغات المحكية",
    __book_Detail1: "تفاصيل الكتاب",
    __book_Price: "سعر الشراء",
  },
  profile: {
    first_name: "الاسم الأول",
    last_name: "الاسم الأخير",
    email: "البريد الإلكتروني",
    mobile: "رقم الهاتف المحمول",
    currentPassword: "كلمة المرور الحالية",
    password: "كلمة المرور",
    cpassword: "أعد إدخال كلمة المرور",
    accept_legal_agreement: "الاتفاقية القانونية",

    __lawyer_PrimaryImageId: "صورة الشخص",
    __lawyer_SecondaryImageIds: "صور الجوائز، الأخبار وغيرها",
    __lawyer_Title: "اسم المحامي",
    __lawyer_Category: "الفئة",
    __lawyer_SubCategory1: "الفئة الفرعية 1",
    __lawyer_SubCategory2: "الفئة الفرعية 2",
    __lawyer_Identifier: "الترخيص",
    __lawyer_DisplayDate: "ممارسة منذ",
    __lawyer_SubTitle: "المؤهل",
    __lawyer_email_address: "عنوان البريد الإلكتروني",
    __lawyer_phone_number: "رقم الهاتف",
    __lawyer_address: "العنوان",
    __lawyer_website_link: "رابط الموقع الإلكتروني",
    __lawyer_Property1: "الولاية",
    __lawyer_Property2: "المدينة",
    __lawyer_Property3: "يصرف",
    __lawyer_PropertyList1: "مجال التخصص",
    __lawyer_PropertyList2: "المحاكم",
    __lawyer_PropertyList3: "اللغات المحكية",
    __lawyer_Detail1: "تفاصيل الملف الشخصي",
    __lawyer_Price: "السعر بالساعة",

    __firm_PrimaryImageId: "صورة الشركة",
    __firm_SecondaryImageIds: "صور الجوائز، الأخبار وغيرها",
    __firm_Title: "اسم الشركة",
    __firm_Category: "الفئة",
    __firm_SubCategory1: "الفئة الفرعية 1",
    __firm_SubCategory2: "الفئة الفرعية 2",
    __firm_Identifier: "الترخيص",
    __firm_DisplayDate: "تأسست في",
    __firm_SubTitle: "عنوان فرعي أو شعار",
    __firm_email_address: "عنوان البريد الإلكتروني",
    __firm_phone_number: "رقم الهاتف",
    __firm_address: "العنوان",
    __firm_website_link: "رابط الموقع الإلكتروني",
    __firm_Property1: "الولاية",
    __firm_Property2: "المدينة",
    __firm_Property3: "يصرف",
    __firm_PropertyList1: "مجال التخصص",
    __firm_PropertyList2: "المحاكم",
    __firm_PropertyList3: "اللغات المحكية",
    __firm_Detail1: "تفاصيل الملف الشخصي",
    __firm_Price: "السعر بالساعة",

    __book_PrimaryImageId: "غلاف الكتاب الأمامي",
    __book_SecondaryImageIds: "الغلاف الخلفي للكتاب، المقدمة، إلخ",
    __book_Title: "عنوان الكتاب",
    __book_Category: "الفئة",
    __book_SubCategory1: "الفئة الفرعية 1",
    __book_SubCategory2: "الفئة الفرعية 2",
    __book_Identifier: "الترخيص",
    __book_DisplayDate: "تاريخ النشر",
    __book_SubTitle: "المؤلف",
    __book_email_address: "عنوان البريد الإلكتروني",
    __book_phone_number: "رقم الهاتف",
    __book_address: "العنوان",
    __book_website_link: "رابط الموقع الإلكتروني",
    __book_Property1: "الولاية",
    __book_Property2: "المدينة",
    __book_Property3: "يصرف",
    __book_PropertyList1: "الوسوم",
    __book_PropertyList2: "المحاكم",
    __book_PropertyList3: "اللغات المحكية",
    __book_Detail1: "تفاصيل الكتاب",
    __book_Price: "سعر الشراء",
  },
  menu: {
    home: "الرئيسية",
    about: "من نحن",
    products: "المنتجات",
    contact: "اتصل بنا",
    account: "حسابي",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    signup: "إنشاء حساب",
  },
  subscription: {
    title: "النشرة الإخبارية",
    description: "اشترك للحصول على آخر التحديثات والأخبار",
    button: "اشترك الآن",
    success: "شكراً لاشتراكك. نتطلع للبقاء على تواصل وتقديم محتوى رائع لك!",
    footer: "نحن نحترم خصوصيتك.",
    name: "الاسم الأول",
    email: "بريدك الإلكتروني",
  },
  news: {
    popular_category: "الفئة الشائعة",
    recent_more: "أحدث الأخبار",
  },
};
